import React, { useState } from "react"
import styled from "styled-components"

import Input from "../../atoms/Input"
import TextArea from "../../atoms/TextArea"
import FormButton from "../../atoms/FormButton"

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  label {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-family: monospace;
  }
  @media (min-width: 992px) {
    padding: 2rem;
  }
`
// To do: Implement contact form submission (Netlify)
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default () => {
  const [state, setState] = useState({})
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        form.reset()
        setState({})
      })
      .catch(error => alert(error))
  }

  return (
    <ContactForm
      data-netlify="true"
      name="contact"
      method="POST"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <label htmlFor="name">Your Full Name</label>
      <Input
        id="name"
        placeholder="Full Name"
        onChange={handleChange}
        type="text"
        name="name"
        required
      />
      <label htmlFor="email">Your Email</label>
      <Input
        id="email"
        placeholder="Email Address"
        onChange={handleChange}
        type="email"
        name="email"
        required
      />
      <label htmlFor="message">
        Describe your project in detail (optional)
      </label>
      <TextArea
        id="message"
        onChange={handleChange}
        name="description"
        placeholder="Please be thorough as possible"
        rows="5"
      />
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <FormButton>Send your message</FormButton>
    </ContactForm>
  )
}
